import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CountdownTimer from "./CountDown";
import { toast } from "react-toastify";
import { PER_USDT_TO_BNB } from "../contracts/contracts";
import useContract from "../hooks/useContract";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import ClipLoader from "react-spinners/ClipLoader";
import { FaTelegramPlane } from "react-icons/fa";
import { FaDiscord } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import Iconslink from "../assets/Linktree-Emblem-removebg-preview.png";
import Roadmap from "../assets/roadmap.jpeg";
import AutoSlider from "./AutoSlider.jsx";
import HeaderSection from "./HeaderSection.jsx";
import AboutSection from "./AboutSection.jsx";
import listing from "./../assets/IMG_0265.png";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const MainPageContainer = styled.div`
  margin: auto;

  hr {
    border: 4px solid white;
  }

  .heading {
    width: 100%;
    font-size: 60px;
    font-weight: 800;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 64px;

    @media (max-width: 800px) {
      margin-bottom: 25px;
      font-size: 45px;
    }
  }
`;

const HeroSection = styled.section`
  display: flex;
  gap: 5em;
  height: 100%;
  padding-left: 2rem;

  @media (max-width: 888px) {
    flex-direction: column;
    gap: 0em;
    width: 90%;
    margin: auto;
    padding: 5rem 0;
  }

  .left {
    width: 50%;
    background: #271919;
    border-radius: 1.2rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    justify-content: flex-start;

    @media (max-width: 888px) {
      width: 100%;
    }
    .main-heading {
      flex-wrap: wrap;
    }

    .live {
      color: white;
      font-weight: 500;
      font-size: 1rem;
      font-family: "Staatliches", sans-serif;
      font-weight: 600;
      letter-spacing: 2px;
      min-width: 64px;
      padding: 8px 10px;
      text-transform: uppercase;
      border-radius: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 1rem;

      i {
        margin-right: 0.2rem;
      }

      .icon {
        border-radius: 50%;
        padding: 0.2rem;
        margin-right: 0.2rem;
      }
    }

    .presale-details {
      display: flex;
      flex-direction: column;
      gap: 0.2em;
      padding: 0rem 1rem 1rem 1rem;

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
      }

      .token {
        color: rgb(255, 255, 255);
        background: rgba(255, 255, 255, 0.3);
        padding: 0.3rem 1em;
        border-radius: 2rem;
        font-weight: 500;
        font-size: 0.875rem;
        min-width: 64px;
        text-align: center;
      }
    }

    h1 {
      text-transform: none;
      font-size: 60px;
      font-weight: 800;
    }

    p {
      font-size: 24px;
      font-weight: 500;
    }
  }

  .right {
    background-color: #271919;
    width: 30%;
    margin: auto;
    display: flex;
    justify-content: flex-start;
    padding: 2em;
    border-radius: 2em;

    color: #fff;

    flex-direction: column;
    @media (max-width: 1184px) {
      width: 50%;
      margin-top: 2em;
    }
    @media (max-width: 888px) {
      width: 100%;
      margin-top: 2em;
    }

    h2 {
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    .timer {
      color: #000;
      font-size: 20px;
      font-weight: 900;
    }

    .details {
      margin: 56px 0;
      display: flex;
      flex-direction: column;
      gap: 8px;
      font-weight: 700;
      font-size: 20px;
      color: #d3d3d3;
    }

    .count {
      font-weight: 700;
      font-size: 20px;
      padding-bottom: 16px;
    }

    .contribute {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        font-weight: 700;
        font-size: 20px;
      }

      select {
        border: 2px solid white;
        padding: 8px;
        color: black;
        background: none;
        font-size: 16px;
        width: 40%;
        border-radius: 8px;
      }
    }

    .amount {
      margin: 15px 0;
      input {
        border: 1px solid white;
        padding: 12px;
        font-size: 16px;
        width: 100%;
        background: none;
        border-radius: 8px;
        color: white;
      }
    }

    .total {
      padding: 12px 0;
      font-weight: 700;
      font-size: 20px;
    }

    .buy_btn {
      color: #d3d3d3;
      background-color: #972022;
      padding: 13px;
      border: none;
      border-radius: 8px;
      font-size: 20px;
      font-family: "Staatliches", sans-serif;
      font-weight: 600;
      letter-spacing: 2px;
      cursor: pointer;
    }
    .buy_btn.disabled {
      background: #999999;
      color: white;
    }
  }
`;

const ListOn = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 0 0 0;
  h1 {
    font-size: 50px;
    font-weight: 800;
    text-align: center;
    margin: 2rem 0 4rem 0;
  }
  .logo_listing {
    display: flex;
    gap: 5em;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    img {
      width: 180px;
      height: 43px;
    }
  }
`;

export const Footer = styled.footer`
  // background: #000;
  padding-bottom: 2em;
  // position: absolute;
  width: 100%;


  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 1em;
    color: #fff;
  }

  li {
    list-style: none;
    color: #fff;
    font-size: 1.2em;
    font-weight: 800;
  }
  .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 3em;
    align-items: center;
    width: 80%;
    margin: auto;
  }

  .first {
    .social-icon {
      display: flex;
      gap: 1em;
      margin: 1em 0;
      color:#fff;
    }

    p {
      color: #fff;
      font-size: 1em;
      font-weight: 600;
    }
  }

  .sec {
    color: white;

    h3 {
      color: white;
      padding-bottom: 1.3em;
    }
  }
  }
`;

const MainPage = () => {
  const [paymenType, setPaymentType] = useState("ETH");
  const [amount, setAmount] = useState(0);
  const [receiveable, setReceiveable] = useState(0);

  const [balance, setBalance] = useState(0);
  const [maxBalance, setMaxBalance] = useState(null);
  const [price, setPrice] = useState();

  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#ffffff");

  const { buy, myTokenBalance, maxBalances, getPrice } = useContract();
  const { address, isConnected } = useWeb3ModalAccount();

  useEffect(() => {
    const _getPrice = async () => {
      const _price = await getPrice();
      console.log(_price);
      setPrice(_price);
    };
    if (isConnected) _getPrice();
  }, [isConnected]);

  useEffect(() => {
    if (paymenType === "ETH") {
      setReceiveable(amount * price * PER_USDT_TO_BNB);
    } else if (paymenType === "USDT") {
      setReceiveable(amount * price);
    } else if (paymenType === "USDC") {
      setReceiveable(amount * price);
    } else {
      console.log(paymenType);
    }
  }, [amount, paymenType]);

  useEffect(() => {
    const _balance = async () => {
      const _myBalance = await myTokenBalance();
      setBalance(_myBalance);
      const _maxBalance = await maxBalances();
      setMaxBalance(_maxBalance);
    };
    if (address) _balance();
  }, [address]);

  const handleBuy = async () => {
    setLoading(true);
    if (paymenType == "ETH") {
      if (amount > maxBalance?.eth) {
        toast.error("Not enough eth balance");
        setLoading(false);
        return;
      }
    } else if (paymenType == "USDT") {
      if (amount > maxBalance.usdt) {
        toast.error("Not enough USDT balance");
        setLoading(false);
        return;
      }
    } else if (paymenType == "USDC") {
      if (amount > maxBalance.busd) {
        toast.error("Not enough BUSD balance");
        setLoading(false);
        return;
      }
    }
    try {
      await buy(paymenType, amount);
      toast.success("Buy Sucessful");
      window.location.reload();
    } catch (err) {
      console.log(err);
      toast.error("Error is Buying");
    }
    setLoading(false);
  };

  return (
    <MainPageContainer>
      <HeaderSection />
      <HeroSection id="home">
        <div className="left">
          <div
            className="flex flex-align-center  main-heading
          "
          >
            <img
              src={require("./../assets/images/logo-3.png")}
              height={110}
              width={120}
              alt=""
            />
            <h2 style={{ color: "white" }}>$CHRLE</h2>
            <div className="live">Live</div>
            <div className="live">KYC</div>
            <div className="live">
              <a
                href="https://www.cyberscope.io/audits/chrle"
                style={{ color: "white", textDecoration: "none" }}
              >
                AUDIT
              </a>
            </div>
          </div>
          <div className="flex flex-align-center gap-1">
            {/* <div className="live">SAFU</div>
            <div className="live">
              <i className="fa-solid fa-lock"></i> AUDIT
            </div> */}
          </div>
          <div className="presale-details" style={{ color: "white" }}>
            <div
              style={{ marginBottom: "10px", flexWrap: "wrap" }}
              className="flex flex-align-center gap-1"
            >
              {/* <p></p> */}
              <a
                href="https://basescan.org/token/0xBde71bB4593C4964dad1A685CbE9Cf6a2cDBDca7"
                style={{
                  color: "white",
                  textDecoration: "none",
                  fontFamily: "Staatliches , sans-erif",
                  fontWeight: 500,
                  letterSpacing: "2px",
                }}
              >
                TOKEN ADDRESS
              </a>
              <p className="token">
                0xBde71bB4593C4964dad1A685CbE9Cf6a2cDBDca7
              </p>
            </div>
            <p style={{ fontWeight: "bold" }}>Token Info</p>
            <p>Current Price: $0.0001</p>
            <p>Next Price: $0.00011</p>
            <p>Launch Price: $0.000235792</p>
            {/* <p>Market Cap: $18,155,000</p> */}
            {/* <p style={{ marginBottom: "5px" }}>Listing Price: 0.00015 </p> */}
            <p>Token Name: CHARLIE</p>
            <p>Token Decimals: 18</p>
            <p>Token Symbol: CHRLE</p>
            <p>Supply: 100 Billion</p>
            {/* <p>Token Hardcap: 540,000 USDT</p>
            <p style={{ marginBottom: "5px" }}>
              Total Allocated Tokens: 135,000,000
            </p> */}
            <p>Starting marketing cap: $18,155,000</p>
            <p>Circulating supply: 70,000,000</p>
            {/* <p>Vesting: 60% claim after presale</p>
            <p style={{ marginBottom: "5px" }}>40% claim by 5% each week</p>
            <p>Tokens will be sent to your wallet after the Presale ends.</p> */}
          </div>
        </div>

        <div className="right">
          <h2 style={{ color: "#D3D3D3" }}>Presale Coming Soon</h2>
          {/* <p className="timer">0&nbsp;:&nbsp;0&nbsp;:&nbsp;95</p> */}

          <div className="details">
            <p>Min : 1 USDT</p>
          </div>

          {/* <div className="count">Claimable: 20 CHRLE</div>
          <div className="count">
            Claimed: {Number(balance).toFixed(4)} CHRLE
          </div> */}

          <div className="contribute">
            <span>Contribute (1USD = 10,000 $CHARLIE)</span>
            <select
              name="cont"
              id="cont"
              style={{ color: "#D3D3D3", fontWeight: 700, fontSize: "1rem" }}
              onChange={(e) => setPaymentType(e.target.value)}
            >
              <option value="ETH">ETH</option>
              <option value="USDC">USDC</option>
            </select>
          </div>

          <div className="amount">
            <input
              type="number"
              name="amount"
              id="amount"
              placeholder={`Enter  Amount`}
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>

          <span className="total">
            Total Amount: {receiveable ? receiveable : 0}
          </span>
          <button
            className={`buy_btn
              }`}
            onClick={loading ? () => {} : handleBuy}
          >
            {loading ? (
              <ClipLoader
                color={color}
                loading={loading}
                cssOverride={override}
                size={20}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            ) : (
              "BUY"
            )}
          </button>
        </div>
      </HeroSection>

      <AboutSection />

      <div style={{ width: "100%", height: "100%" }} id="roadmap">
        <img
          src={Roadmap}
          alt="roadmap"
          loading="lazy"
          style={{ width: "100%", height: "100%" }}
        />
      </div>
      <ListOn style={{ marginBottom: "6rem" }}>
        <div className="lis">
          <h1 className="lis_absolute">LISTING ON</h1>
          <img src={listing} alt="" className="lis_image" />
        </div>
        {/* <div className="logo_listing">
          <img src={require("./../assets/images/logo1.png")} alt="" />
          <img src={require("./../assets/images/logo2.png")} alt="" />
          <img src={require("./../assets/images/logo3.png")} alt="" />
          <img src={require("./../assets/images/logo4.png")} alt="" />
          <img src={require("./../assets/images/logo5.png")} alt="" />
        </div> */}
        <AutoSlider interval={3000} />
      </ListOn>
      <Footer>
        <div className="container">
          <div className="first">
            <img
              src={require("./../assets/images/logo-3.png")}
              height={90}
              width={100}
              alt=""
            />
            <div
              className="social-icon"
              style={{ display: "flex", alignItems: "center" }}
            >
              <a
                style={{ textDecoration: "none", color: "#fff" }}
                href="https://linktr.ee/charlieunicoin"
              >
                Linktree 🍀
              </a>
              <a href="https://t.me/CharlieTheUnicoinOfficial">
                <FaTelegramPlane className="social-icons" />
              </a>
              <a href="https://discord.com/invite/charlietheunicoin">
                <FaDiscord className="social-icons" />
              </a>
              <a href="https://twitter.com/Charlie_Unicoin">
                <FaTwitter className="social-icons" />
              </a>
            </div>
            <div></div>
            <p>Copyright © 2024 CHARLIE THE UNICOIN. All rights reserved.</p>
          </div>
          <div className="sec">
            <h3 style={{ color: "#fff" }}>Quick Links</h3>
            <ul>
              <li>
                <a href="#home" style={{ textDecoration: "none" }}>
                  HOME
                </a>
              </li>
              {/* <li> <a href="#home" style={{ textDecoration:'none'}}>Presale</a></li> */}
              <li>
                <a
                  href="https://www.charlietheunicoin.com/white-paper"
                  style={{ textDecoration: "none" }}
                >
                  WHITEPAPER
                </a>
              </li>

              {/* <li>Tokenomics</li> */}
              {/* <li>FAQ</li> */}
              <li>
                <a href="#roadmap" style={{ textDecoration: "none" }}>
                  ROADMAP
                </a>
              </li>
            </ul>
          </div>
          <div></div>
        </div>
      </Footer>
    </MainPageContainer>
  );
};

export default MainPage;
