import { BrowserProvider, Contract, formatUnits, parseUnits } from "ethers";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";

import Web3 from "web3";

import {
  TOKEN_CONTRACT_ADDRESS,
  PRESALE_CONTRACT_ADDRESS,
  USDT_CONTRACT_ADDRESS,
  USDC_CONTRACT_ADDRESS,
  TOKEN_ABI,
  PRESALE_ABI,
} from "../contracts/contracts";

function useContract() {
  const { walletProvider } = useWeb3ModalProvider();
  const { address, isConnected, chainId } = useWeb3ModalAccount();
  const getProvider = () => {
    return new BrowserProvider(walletProvider);
  };
  const getSigner = async (provider) => {
    return provider.getSigner();
  };

  const getContract = async (address, abi, signer) => {
    const contract = new Contract(address, abi, signer);
    return contract;
  };

  const buy = async (paymentType, amount) => {
    console.log(paymentType, amount);

    const provider = getProvider();
    const signer = await getSigner(provider);
    // print singer address
    const contract = await getContract(
      PRESALE_CONTRACT_ADDRESS,
      PRESALE_ABI,
      signer
    );

    if (paymentType === "ETH") {
      console.log("ETH");
      const transaction = await contract.buyFromNative(
        "0x0000000000000000000000000000000000000000",
        {
          value: parseUnits(amount.toString(), 18),
        }
      );
      const receipt = await transaction.wait();
      return receipt;
    } else if (paymentType === "USDT") {
      const usdt = await getContract(USDT_CONTRACT_ADDRESS, TOKEN_ABI, signer); // usdt contract
      const transaction = await usdt.approve(
        // approving usdt contract
        PRESALE_CONTRACT_ADDRESS,
        parseUnits(amount.toString(), 18)
      );
      await transaction.wait();

      const trx2 = await contract.buyfromToken(
        // buying from token
        1,
        "0x0000000000000000000000000000000000000000",
        parseUnits(amount.toString(), 18)
      );

      await trx2.wait();
    } else if (paymentType === "USDC") {
      const usdc = await getContract(USDC_CONTRACT_ADDRESS, TOKEN_ABI, signer);
      const transaction = await usdc.approve(
        PRESALE_CONTRACT_ADDRESS,
        parseUnits(amount.toString(), 18)
      );
      await transaction.wait();

      const trx2 = await contract.buyfromToken(
        // buying from token
        2,
        "0x0000000000000000000000000000000000000000",
        parseUnits(amount.toString(), 18)
      );
      await trx2.wait();
    }
  };

  const getData = async () => {
    let token;
    if (!isConnected) {
      // const web3 = new Web3(
      //   "https://sepolia.infura.io/v3/e0211fbb295f42f6a73d2be9647e7427"
      // );
      // token = new web3.eth.Contract(TOKEN_ABI, TOKEN_CONTRACT_ADDRESS);
      return;
    } else {
      const provider = getProvider();
      const signer = await getSigner(provider);
      token = await getContract(TOKEN_CONTRACT_ADDRESS, TOKEN_ABI, signer);
    }

    const balance = await token.balanceOf(address);
    const balanceInEth = formatUnits(balance, 18);
    // console.log(balanceInEth);
    // contract token balance
    const contractBalanceInEth = await token.balanceOf(
      PRESALE_CONTRACT_ADDRESS
    );
    const contractBalance = formatUnits(contractBalanceInEth, 18);

    return {
      balanceInEth,
      contractBalance,
    };
  };

  const myTokenBalance = async () => {
    console.log(chainId);

    let token;
    if (!isConnected) {
      return 0;
    } else {
      const provider = getProvider();
      const signer = await getSigner(provider);
      token = await getContract(TOKEN_CONTRACT_ADDRESS, TOKEN_ABI, signer);
      const balance = await token.balanceOf(address);
      const balanceInEth = formatUnits(balance, 18);
      return balanceInEth;
    }
  };

  const maxBalances = async () => {
    let token;
    let token2;
    let usdcBalance;
    let usdtBalance;
    let ethbalance;
    if (!isConnected) {
      return {
        usdt: 0,
        busd: 0,
        eth: 0,
      };
    } else {
      const provider = getProvider();
      const signer = await getSigner(provider);
      token = await getContract(USDT_CONTRACT_ADDRESS, TOKEN_ABI, signer);
      token2 = await getContract(USDC_CONTRACT_ADDRESS, TOKEN_ABI, signer);
      usdtBalance = await token.balanceOf(address);

      usdcBalance = await token2.balanceOf(address);

      // eth balance
      ethbalance = await provider.getBalance(address);
    }

    return {
      usdt: Number(formatUnits(usdtBalance, 18)).toFixed(4),
      busd: Number(formatUnits(usdcBalance, 18)).toFixed(4),
      eth: Number(formatUnits(ethbalance, 18)).toFixed(4),
    };
  };

  const getPrice = async () => {
    let contract;
    let price;
    if (!isConnected) {
      // const web3 = new Web3(
      //   "https://sepolia.infura.io/v3/e0211fbb295f42f6a73d2be9647e7427"
      // );
      // contract = new web3.eth.Contract(PRESALE_ABI, PRESALE_CONTRACT_ADDRESS);
      // price = await contract.methods.perDollarPrice().call();
      return 0;
    } else {
      const provider = getProvider();
      const signer = await getSigner(provider);
      contract = await getContract(
        PRESALE_CONTRACT_ADDRESS,
        PRESALE_ABI,
        signer
      );
      price = await contract.perDollarPrice();
      return Number(formatUnits(price, 18)).toFixed(4);
    }
  };

  return {
    buy,
    getData,
    myTokenBalance,
    maxBalances,
    getPrice,
  };
}

export default useContract;
