import React from "react";
import aboutleft from "./../assets/about-left.png";
import aboutright from "./../assets/about-right1.png";
import imgAbout from "./../assets/IMG_0265.png";

import "./aboutSection.css";

function AboutSection() {
  return (
    <div className="about_section" id="aboutus">
      <div className="about_images">
        <div>
          <img src={aboutleft} alt="" className="about_image1" />
        </div>
        <div  style={{display:"flex",alignItems:"center", justifyContent:"center"}}>
            <h2 className="about_absolute">ABOUT</h2>
          <img src={imgAbout} alt="" className="about_image2" />
        </div>
        <div>
          <img src={aboutright} alt="" className="about_image3" />
        </div>
      </div>
      <p>
        Tired of the same old dog memes flooding your feed? Us too. We wanted
        something fresh, something untouched by the repetitive paws of canine
        content. So, we geared up for a meme hunt, hoping to strike gold. But
        when the meme gods let us down, we said, Fuck it! And decided to create
        something wilder than a crypto billionaire's wet dream.
      </p>
    </div>
  );
}

export default AboutSection;
