import React from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { useWeb3Modal } from "@web3modal/ethers/react";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";

const NavbarContainer = styled.div`
  display: flex;
  max-width: 1536px;
  margin: auto;
  background-color: #1e1105;

  .logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    p {
      color: black;
      font-size: 30px;
      font-weight: bold;
      font-family: "Alro", sans-serif;
    }
    img {
      height: 68px;
      width: 78px;
    }
  }

  gap: 3;

  @media (max-width: 1570px) {
    padding: 0 1em;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    padding-bottom: 20px;
    .logo3 {
      display: block;
      width: 50px;
      height: 50px;
      margin: 0 auto;
    }
  }
`;

const NavLink = styled.div`
  display: flex;
  align-items: center;

  a {
    color: #d3d3d3;
    font-family: "Staatliches", sans-serif;
    font-weight: 600;
    letter-spacing: 2px;
    padding: 1rem;
    text-decoration: none;
    font-size: 2.5rem;
    line-height: 24px;
    @media (max-width: 800px) {
      padding: 6px;
      font-size: 1rem;
      justify-content: center;
      width: 100%;
    }
  }
`;

const NavButton = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 1000px) {
    justify-content: center;
  }
`;

const Button = styled.button`
  background: transparent;
  border: 1px solid white;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  font-weight: 1000;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background: #e2e2e2;
    color: #000;
  }
`;

const Navbar = () => {
  const { open } = useWeb3Modal();
  const { isConnected, address } = useWeb3ModalAccount();
  const notify = () => toast.success("NO CONTENT FOUND");
  return (
    <NavbarContainer>
      <img
        className="logo3"
        src={require("./../assets/images/logo-3.png")}
        height={110}
        width={120}
        alt=""
      />
      <NavLink>
        <Link to={"/"}>HOME</Link>
        <a href="#aboutus">ABOUT</a>
        <a href="https://www.charlietheunicoin.com/">FEATURES</a>
        <a href="#roadmap">ROADMAP</a>
      </NavLink>
      <NavButton>
        <Button
          onClick={isConnected ? () => open("Account") : () => open("Connect")}
        >
          {isConnected
            ? address.substring(0, 4) +
              "***" +
              address.substring(address.length - 4, address.length)
            : "CONNECT WALLET"}
        </Button>
      </NavButton>
    </NavbarContainer>
  );
};

export default Navbar;
