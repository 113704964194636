import RoutesFile from "./RoutesFile";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Roadmap from "./components/Roadmap";

// 1. Get projectId
const projectId = "5bcd747094b5304393db57d60c2ed40e";

// 2. Set chains
const mainnet = {
  chainId: 1,
  name: "Ethereum",
  currency: "ETH",
  explorerUrl: "https://etherscan.io",
  rpcUrl: "https://cloudflare-eth.com",
};

const sepolia = {
  chainId: 11155111,
  name: "Sepolia",
  currency: "ETH",
  explorerUrl: "https://rpc.sepolia.org",
  rpcUrl: "https://1rpc.io/sepolia",
};

const base = {
  chainId: 8453,
  name: "BASE",
  currency: "ETH",
  explorerUrl: "https://basescan.org/",
  rpcUrl: "https://base.llamarpc.com",
};

// 3. Create a metadata object
const metadata = {
  name: "My Website",
  description: "My Website description",
  url: "https://mywebsite.com", // origin must match your domain & subdomain
  icons: ["https://avatars.mywebsite.com/"],
};

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,
});

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: [base],
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
});
function App() {
  return (
    <>
      <RoutesFile />
      <ToastContainer />
      {/* <Roadmap/> */}
    </>
  );
}

export default App;
