import React from 'react'
import gm from "../assets/images/headerSection/GMz7PDJW0AAn9T5.jpeg";
import kyc from "../assets/images/headerSection/kyc.png";
import sc from "../assets/images/headerSection/sc.png";
import logo from "./../assets/images/logo-3.png";

import "./headerSection.css"

function HeaderSection() {
  return (
    <div className="header_section">
      <div>
        <img
          src={logo}
          alt="logo"
          className="header_image1"
        />
      </div>
          <div>
              <p>CHARLIE</p>
        <img src={sc} alt="cyberScope" className="header_image2" />
      </div>
      <div>
        <img src={gm} alt="audit" className="header_image3" />
      </div>
      <div>
        <img src={kyc} alt="kyc" className="header_image4" />
      </div>
    </div>
  );
}

export default HeaderSection